<style lang="scss" src="./Drawer.scss"></style>
<script lang="ts" src="./Drawer.ts"></script>

<template>
  <q-drawer :width="225" v-model="leftDrawerOpen">
    <div class="drawer-vault-logo"></div>
    <img src="../../assets/images/leftnav-bg-grad.png" class="drawer-top-img" />
    <q-btn
      flat
      dense
      round
      @click="clickleftDrawer()"
      aria-label="Menu"
      :icon="chevronChoice"
      class="drawer-toggle-btn"
    />
    <Navigation />
  </q-drawer>
</template>
