<style lang="scss" src="./Navigation.scss"></style>
<script lang="ts" src="./Navigation.ts"></script>

<template>
  <q-list class="navigation-qlist">
    <q-item
      clickable
      tag="a"
      :href="item.href"
      v-for="item in list"
      :key="item.id"
      :class="[item.isActive ? 'active' : '']"
    >
      <q-item-section avatar>
        <font-awesome-icon :icon="item.iconClass" size="lg" class="width-24" />
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ item.text }}</q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>
