<style lang="scss" src="./DropDownButton.scss"></style>
<script lang="ts" src="./DropDownButton.ts"></script>

<template>
  <div class="relative-position" @mouseleave="showList = false">
    <div class="col-12 items-center dropdown-container">
      <div class="row shadow-2 border-radius-8">
        <div class="col-9">
          <button
            @click="showList = !showList"
            class="q-pa-sm dropdown-button dropdown-button-text"
            :class="
              property.title.search('action') !== -1 ? 'theme-2' : 'theme-1'
            "
          >
            <font-awesome-icon
              :style="{ color: property.icon.color }"
              :size="property.icon.size"
              :icon="property.icon.name"
            />
            <span class="q-pl-sm">{{ $t(property.title) }}</span>
          </button>
        </div>
        <div class="col-3">
          <button
            @click="showList = !showList"
            class="q-pa-sm dropdown-button dropdown-button-arrow"
            :class="
              property.title.search('action') !== -1 ? 'theme-2' : 'theme-1'
            "
          >
            <font-awesome-icon
              :style="{ color: property.arrowIcon.color }"
              :icon="property.arrowIcon.name"
              :class="showList ? 'rotate-icon-reverse' : 'rotate-icon'"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      class="button-list button-list-container"
      :class="showList ? 'button-list-visible ' : 'button-list-invisible'"
    >
      <slot name="dropDownGoogle" />
      <slot name="dropDownAction" />
    </div>
  </div>
</template>
