import { defineComponent, onMounted, ref, Ref } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';
import formValidation from "@/base/form-validation/form-validation";
import { FilterModel } from "@/models/filter";
import { FILTER } from "@/services/filter-service"
import { GET_CONTENT, POST_CONTENT } from "@/services/content-service"
import { ContentModel } from "@/models/content";
import { currentSiteId } from "@/extensions/extra";
import { generateFormFilterArray } from "@/utils/generateFormFilterArray";
import { postFields } from "@/utils/postFields";
import { formatRows, formatColumns } from "@/utils/formatGrid";
import { TableGridFields } from "@/base/core/enum";
import { formatImages } from "@/utils/formatImages";
import i18n from "@/i18n";
import { returnErrors } from "@/utils/returnErrors";

export default defineComponent({
  name: "FormsFilter",
  props: {
    filterOriginalUrl: {
      type: String,
    },
    response: {
      type: String,
    },
    dialogOptions: {
      type: Array,
      default: () => []
    }
  },
  emits: ["filteredList", "displayTableName", "chipArray", 'filteredPagination', 'sendFilterParams'],

  setup(props, { emit }) {
    const $q = useQuasar();
    const moreFilters = ref(false);
    const filterModel = new FilterModel();
    const formElements: Ref<any[]> = ref([]);
    const contentModel = new ContentModel();
    const formData: any = [];
    const rows = ref();
    const cols = ref();
    const pagination = ref()
    let hiddenTableFields: any = [];
    const chipArray: Ref<any> = ref([])
    const showDialog: Ref<boolean> = ref(false)
    const dialogInputs = ref(props.dialogOptions)
    const disable = ref(true);

    onMounted(() => {
      getContent();
    })

    async function getContent() {
      contentModel.currentSiteId = currentSiteId;
      contentModel.originalUrl = props.filterOriginalUrl;
      const [error, response] = await GET_CONTENT(contentModel)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        formData.value = response.list.forms;
        formElements.value = generateFormFilterArray(response.list.forms);
        for (const t of formElements.value) {
          const dialogCondtion = dialogInputs.value?.findIndex((e => t.propertyName === e))
          if (dialogCondtion !== -1) {
            t.isDialog = false
          } else {
            t.isDialog = true
          }
        }
      }
    }

    function filter() {
      if (props.response === 'self') {
        emit('sendFilterParams', formElements.value);
      }
      const startDateIndex = formElements.value.findIndex(t => t.propertyName === 'Filter_StartDate')
      const endDateIndex = formElements.value.findIndex(t => t.propertyName === 'Filter_EndDate')
      if (formElements.value[startDateIndex] && formElements.value[endDateIndex]) {
        if (formElements.value[startDateIndex].value.length > 0 && formElements.value[endDateIndex].value.length > 0) {
          applyFilter()
        } else if (formElements.value[startDateIndex].value.length === 0 && formElements.value[endDateIndex].value.length === 0) {
          applyFilter()
        } else {
          $q.notify({
            group: false,
            color: 'warning',
            message: (formElements.value[startDateIndex].value.length === 0 ?
              i18n.global.t("dateFrom.definition").toString() : i18n.global.t("dateTill.definition").toString())
              + ' ' + i18n.global.t("dateWarn.definition").toString(),
            position: 'top',
            icon: 'information'
          });
        }
      } else {
        applyFilter()
      }
    }

    async function applyFilter() {
      for (const t of formElements.value) {
        formData.value = postFields(formData.value, t.propertyName, t.value);
      }
      filterModel.currentSiteId = currentSiteId;
      filterModel.originalUrl = props.filterOriginalUrl;
      filterModel.postedField = "searchBtn";
      filterModel.forms = formData.value;
      filterChipValues(formElements.value)
      const [error, response] = await FILTER(filterModel)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        if (response.list.grids) {
          rows.value = [];
          cols.value = [];
          hiddenTableFields = [];
          cols.value = formatColumns(response.list.grids[0].columns);
          rows.value = formatRows(response.list.grids[0].rows, response.list.grids[0].columns);
          pagination.value = response.list.grids[0].pagination
          for (let i = 0; i < cols.value.length; i++) {
            if (cols.value[i].title === TableGridFields.SKU.toString() ||
              cols.value[i].title === TableGridFields.Image.toString() ||
              cols.value[i].title === TableGridFields.KeyProviderImageURL.toString()) {
              hiddenTableFields.push(i);
            }
          }
          for (let i = hiddenTableFields.length - 1; i >= 0; i--) {
            cols.value.splice(hiddenTableFields[i], 1);
          }
          rows.value = formatImages(rows.value);
          // product list toggle buttons
          for (let i = 0; i < rows.value.length; i++) {
            if (rows.value[i].IsConnected === "True") {
              rows.value[i].IsConnected = true;
            } else {
              rows.value[i].IsConnected = false;
            }
          }
          emit('filteredList', rows.value);
          emit('filteredPagination', pagination.value)
        }
      }
      emit("displayTableName", "filterTable");
    }

    function filterChipValues(fields: any) {
      chipArray.value = [];
      for (const t of fields) {
        if (t.vueType === 5 && t.value.length > 0) {
          chipArray.value.push({ title: t.value, value: "0", propertyName: t.propertyName })
        } if (t.vueType === 13 && t.value.length > 0) {
          chipArray.value.push({ title: t.value, value: "0", propertyName: t.propertyName })
        } else if (t.vueType === 2 && (t.value > 0 || t.value.length > 0)) {
          const option = t.options.filter((e: { value: null }) => e.value === t.value)[0]
          chipArray.value.push({ title: option.text, value: option.value, propertyName: t.propertyName })
        }
      }
      emit("chipArray", chipArray.value)
    }

    function clearFilter() {
      emit("displayTableName", "dataTable");
      emit("chipArray", [])
      formElements.value = [];
      disable.value = true;
      setTimeout(function () {
        getContent();
      }, 500);
    }

    function substractFilterItem(item: any, length: number) {
      const index = formElements.value.findIndex((t: { propertyName: null }) => item.propertyName === t.propertyName)
      formElements.value[index].value = ""
      filter()
      if (chipArray.value.length === 0) clearFilter()
    }

    function endDateAutoLimit(date: any) {
      const startDateElement = formElements.value.filter(t => t.propertyName === "Filter_StartDate")
      const limitedDate = startDateElement[0].value.split("/").reverse().join("/");
      return limitedDate
    }

    function startDateAutoLimit(date: any) {
      const endDateElement = formElements.value.filter(t => t.propertyName === "Filter_EndDate")
      const limitedDate = endDateElement[0].value.split("/").reverse().join("/");
      return limitedDate.length === 0 ? date : limitedDate
    }

    function enableApplyButton(type: any) {
      if (type == 'Filter_StartDate' || type == 'Filter_EndDate') {
        for (let i = 0; i < formElements.value.length; i++) {
          if (formElements.value[i].propertyName == 'Filter_Period') {
            formElements.value[i].value = "";
          }
        }
      } else if (type == 'Filter_Period') {
        for (let i = 0; i < formElements.value.length; i++) {
          if (formElements.value[i].propertyName == 'Filter_StartDate' || formElements.value[i].propertyName == 'Filter_EndDate') {
            formElements.value[i].value = "";
          }
        }
      }
      disable.value = false;
    }

    return {
      rules: formValidation.rules,
      moreFilters,
      filter,
      clearFilter,
      substractFilterItem,
      formElements,
      currentSiteId,
      rows,
      cols,
      showDialog,
      endDateAutoLimit,
      startDateAutoLimit,
      enableApplyButton,
      disable
    }
  },
})