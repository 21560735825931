import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "text-left",
  style: {"width":"150px"}
}
const _hoisted_2 = { class: "text-right" }
const _hoisted_3 = { class: "text-right" }
const _hoisted_4 = { class: "text-right" }
const _hoisted_5 = { class: "text-right" }
const _hoisted_6 = { class: "text-right" }
const _hoisted_7 = { class: "text-left" }
const _hoisted_8 = { class: "text-right" }
const _hoisted_9 = { class: "text-right" }
const _hoisted_10 = { class: "text-right" }
const _hoisted_11 = { class: "text-right" }
const _hoisted_12 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_markup_table = _resolveComponent("q-markup-table")!

  return (_openBlock(), _createBlock(_component_q_markup_table, { class: "vltn-table promotion-list-table" }, {
    default: _withCtx(() => [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_1, [
            _createVNode(_component_q_skeleton, { type: "text" })
          ]),
          _createElementVNode("th", _hoisted_2, [
            _createVNode(_component_q_skeleton, { type: "text" })
          ]),
          _createElementVNode("th", _hoisted_3, [
            _createVNode(_component_q_skeleton, { type: "text" })
          ]),
          _createElementVNode("th", _hoisted_4, [
            _createVNode(_component_q_skeleton, { type: "text" })
          ]),
          _createElementVNode("th", _hoisted_5, [
            _createVNode(_component_q_skeleton, { type: "text" })
          ]),
          _createElementVNode("th", _hoisted_6, [
            _createVNode(_component_q_skeleton, { type: "text" })
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(25, (n) => {
          return _createElementVNode("tr", { key: n }, [
            _createElementVNode("td", _hoisted_7, [
              _createVNode(_component_q_skeleton, {
                type: "text",
                width: "85px"
              })
            ]),
            _createElementVNode("td", _hoisted_8, [
              _createVNode(_component_q_skeleton, {
                type: "text",
                width: "50px"
              })
            ]),
            _createElementVNode("td", _hoisted_9, [
              _createVNode(_component_q_skeleton, {
                type: "text",
                width: "35px"
              })
            ]),
            _createElementVNode("td", _hoisted_10, [
              _createVNode(_component_q_skeleton, {
                type: "text",
                width: "65px"
              })
            ]),
            _createElementVNode("td", _hoisted_11, [
              _createVNode(_component_q_skeleton, {
                type: "text",
                width: "25px"
              })
            ]),
            _createElementVNode("td", _hoisted_12, [
              _createVNode(_component_q_skeleton, {
                type: "text",
                width: "85px"
              })
            ])
          ])
        }), 64))
      ])
    ]),
    _: 1
  }))
}