export function formatColumns(arr) {
    let returnArr = [];
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].title === "Platform (DRM)") {
            returnArr.push({
                title: arr[i].title,
                align: "left",
                label: arr[i].title,
                field: arr[i].title,
                name: "PlatformDRM",
                required: true,
                sortable: false
            });
        } else if (arr[i].title === "PackShotImageURL" || arr[i].title === "VaultImageURL") {
            returnArr.push({
                title: arr[i].title,
                align: "left",
                label: "Image",
                field: arr[i].title,
                name: arr[i].title,
                required: true,
                sortable: false
            });
        } else if (arr[i].title === "Action_ConnectProduct" || arr[i].title === "Action_DisconnectProduct") {
            returnArr.push({
                title: arr[i].title,
                align: "left",
                label: "",
                field: arr[i].title,
                name: arr[i].title,
                required: true,
                sortable: false
            });
        } else if (arr[i].title === "Valid from") {
            returnArr.push({
                title: "ValidFrom",
                align: "left",
                label: "ValidFrom",
                field: "ValidFrom",
                name: "ValidFrom",
                required: true,
                sortable: false
            });
        } else if (arr[i].title === "Valid to") {
            returnArr.push({
                title: "ValidTo",
                align: "left",
                label: "ValidTo",
                field: "ValidTo",
                name: "ValidTo",
                required: true,
                sortable: false
            });
        } else {
            returnArr.push({
                title: arr[i].title,
                align: "left",
                label: arr[i].title,
                field: arr[i].title,
                name: arr[i].title,
                required: true,
                sortable: false
            });
        }
    }
    // extra td for action buttons
    returnArr.push({
        align: "left",
        label: "",
        name: "actions",
        sortable: false
    });
    return returnArr;
}

export function formatRows(rows, cols) {
    let rowsFormat = [];
    let columns = [];
    let rowsTempFormat = [];
    let rowsFormatted = [];
    let object = {};
    columns = formatColumns(cols);
    for (let i = 0; i < rows.length; i++) {
        rowsFormat.push(rows[i].items);
    }
    for (let i = 0; i < rows.length; i++) {
        rowsFormat[i].push({
            originalURL: rows[i].href
        });
    }
    for (let i = 0; i < rowsFormat.length; i++) {
        for (let j = 0; j < rowsFormat[i].length; j++) {
            rowsFormat[i][j][columns[j].title] = rowsFormat[i][j].value;
            delete rowsFormat[i][j]["value"];
            delete rowsFormat[i][j]["vueType"];
            delete rowsFormat[i][j]["canWrap"];
        }
    }
    for (let i = 0; i < rowsFormat.length; i++) {
        for (let j = 0; j < rowsFormat[i].length; j++) {
            rowsTempFormat[i] = [].concat(rowsTempFormat[i], rowsFormat[i][j]);
            delete rowsTempFormat[i][0];
            object = {};
            rowsTempFormat[i].forEach((item) => {
                object = { ...object, ...item }
            });
        }
        Object.keys(object).forEach(key => {
            if (object[key] == undefined) {
                delete object[key];
            }
        });
        rowsFormatted.push(object);
    }
    return rowsFormatted;
}

export function addEditRow(arr) {
    let returnArr = [];
    let editArr = {
        "edit": [
            {
                "icon": "far fa-rhombus",
                "label": "Connect Request",
                "isDisable": false,
                "action": 1
            },
            {
                "icon": "far fa-plus",
                "label": "Add to my Catalog",
                "isDisable": true,
                "action": 2
            },
            {
                "icon": "far fa-message-pen",
                "label": "Suggest Change",
                "isDisable": true,
                "action": 3
            },
            {
                "icon": "far fa-bell",
                "label": "Report Duplicate",
                "isDisable": false,
                "action": 4
            }
        ]
    };
    for (let i = 0; i < arr.length; i++) {
        let temp = { ...arr[i], ...editArr };
        returnArr.push(temp);
    }
    return returnArr;
}

export function formatColumnsXLXS(arr) {
    let returnArr = [];
    for (let i = 0; i < arr.length; i++) {
        returnArr.push({
            label: arr[i].title,
            field: arr[i].title,
        });
    }
    return returnArr;
}

export function convertColumnsXLXS(arr) {
    let returnArr = [];
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === "Valid from") {
            returnArr.push({
                title: "ValidFrom",
                align: "left",
                label: "ValidFrom",
                field: "ValidFrom",
                name: "ValidFrom",
                required: true,
                sortable: false
            });
        } else if (arr[i] === "Valid to") {
            returnArr.push({
                title: "ValidTo",
                align: "left",
                label: "ValidTo",
                field: "ValidTo",
                name: "ValidTo",
                required: true,
                sortable: false
            });
        } else {
            returnArr.push({
                title: arr[i],
                align: "left",
                label: arr[i],
                field: arr[i],
                name: arr[i],
                required: true,
                sortable: false
            });
        }
    }
    return returnArr;
}

export function convertRowsXLXS(rows, cols) {
    let rowsFormat = [];
    let object = {};
    let objectTemp = {};
    for (let i = 0; i < cols.length; i++) {
        object[cols[i].title] = null;
    }
    for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < rows[i].length; j++) {
            objectTemp[Object.keys(object)[j]] = rows[i][j];
        }
        objectTemp = {}
        rowsFormat.push(objectTemp);
    }
    rowsFormat.splice(-1, 1) // delete last empty array element
    return rowsFormat;
}