<style lang="scss" src="./TablePager.scss"></style>
<script lang="ts" src="./TablePager.ts"></script>
<template>
  <q-table
    v-if="(tableName == 'dataTable' || tableName == null) && rows && cols"
    :loading="loading"
    :rows="rows"
    :columns="cols"
    :pagination="_pagination"
    flat
    bordered
    row-key="name"
    class="relative-position vltn-table custom-colored"
    @row-click="rowClick"
    :rows-per-page-options="[0]"
  >
    <template v-slot:body-cell-Name="props">
      <q-td :props="props">
        <span class="font-weight-600" v-html="props.value"></span>
      </q-td>
    </template>
    <template v-slot:body-cell-Available="props">
      <q-td :props="props">
        <span class="font-weight-600" v-html="props.value"></span>
      </q-td>
    </template>
    <template v-slot:body-cell-Title="props">
      <q-td :props="props">
        <span class="font-weight-600 dark-blue" v-html="props.value"></span>
      </q-td>
    </template>
    <template v-slot:body-cell-Vault="props">
      <q-td :props="props">
        <span class="font-weight-600 dark-blue" v-html="props.value"></span>
      </q-td>
    </template>
    <template v-slot:body-cell-PackShotImageURL="props">
      <q-td :props="props" class="width-80">
        <q-img :src="props.value" />
      </q-td>
    </template>
    <template v-slot:body-cell-PlatformDRM="props">
      <q-td :props="props">
        <div v-html="props.value"></div>
      </q-td>
    </template>
    <template v-slot:body-cell-VaultImageURL="props">
      <q-td :props="props">
        <q-img :src="props.value" />
      </q-td>
    </template>
    <template v-slot:body-cell-Logo="props">
      <q-td :props="props">
        <span class="keyProviderImage">
          <q-img :src="props.value" />
        </span>
      </q-td>
    </template>
    <template v-slot:body-cell-Action_ConnectProduct="props">
      <q-td :props="props" v-if="props.value">
        <q-toggle
          v-model="props.row.IsConnected"
          checked-icon="check"
          color="green"
          unchecked-icon="clear"
          @click="callToActionButtonParent(props.row, 'connected')"
          data-cy="myVaultProductsConnectProduct-button"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-Action_DisconnectProduct="props">
      <q-td :props="props" v-if="props.value">
        <q-toggle
          v-model="props.row.IsConnected"
          checked-icon="check"
          color="green"
          unchecked-icon="clear"
          @click="callToActionButtonParent(props.row, 'disConnected')"
          data-cy="myVaultProductsDisconnectProduct-button"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-Connected="props">
      <q-td :props="props">
        <q-icon class="red" name="circle" v-if="props.value === '0'" />
        <q-icon class="green" name="circle" v-if="props.value === '1'" />
      </q-td>
    </template>
    <template v-slot:body-cell-Active="props">
      <q-td :props="props">
        <q-icon class="red" name="circle" v-if="props.value === 'False'" />
        <q-icon class="green" name="circle" v-if="props.value === 'True'" />
      </q-td>
    </template>
    <template v-slot:body-cell-SKU="props">
      <q-td :props="props">
        <SkuCard :skuData="props.value" :rowData="props.row" />
      </q-td>
    </template>
    <template v-slot:body-cell-actions="props" v-if="actionsCell != false">
      <q-td :props="props">
        <q-btn-dropdown color="primary" icon="more_vert">
          <q-list class="table-actions">
            <q-item
              v-close-popup
              v-if="componentName"
              :to="{
                name: componentName,
                params: { id: props.row.ID },
              }"
              data-cy="commonTableDetail-button"
            >
              <q-item-section>
                <q-item-label>
                  <q-icon name="edit" />
                  {{ $t("tablePager.details") }}
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              clickable
              v-close-popup
              v-on:click.stop="sendPropToParent(props.row)"
              v-if="!componentName"
              data-cy="commonTableDetail-button"
            >
              <q-item-section>
                <q-item-label>
                  <q-icon name="edit" />
                  {{ $t("tablePager.edit") }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-td>
    </template>
    <template v-slot:pagination>
      <span class="pager-info">
        {{ pagerInfo }}{{ $t("of.definition") }} {{ _pagination.rowsNumber }}
      </span>
      <q-btn
        icon="chevron_left"
        round
        dense
        flat
        :disable="_pagination.page <= 1"
        @click="prevPage"
        data-cy="commonTablePreviousPage-button"
      />
      <q-btn
        icon="chevron_right"
        round
        dense
        flat
        :disable="_pagination.page >= _pagination.rowsNumber / _pagination.rowsPerPage"
        @click="nextPage"
        data-cy="commonTableNextPage-button"
      />
    </template>
  </q-table>
  <!-- Filter table -->
  <q-table
    :loading="loading"
    class="vltn-table custom-colored"
    v-if="tableName == 'filterTable' && filteredDataArr && cols"
    :rows="filteredDataArr"
    :columns="cols"
    :pagination="_pagination"
    @row-click="rowClick"
    :rows-per-page-options="[0]"
    flat
    bordered
  >
    <template v-slot:body-cell-Name="props">
      <q-td :props="props">
        <span class="font-weight-600" v-html="props.value"></span>
      </q-td>
    </template>
    <template v-slot:body-cell-Available="props">
      <q-td :props="props">
        <span class="font-weight-600" v-html="props.value"></span>
      </q-td>
    </template>
    <template v-slot:body-cell-Title="props">
      <q-td :props="props">
        <span class="font-weight-600 dark-blue" v-html="props.value"></span>
      </q-td>
    </template>
    <template v-slot:body-cell-Vault="props">
      <q-td :props="props">
        <span class="font-weight-600 dark-blue" v-html="props.value"></span>
      </q-td>
    </template>
    <template v-slot:body-cell-PackShotImageURL="props">
      <q-td :props="props" class="width-80">
        <q-img :src="props.value" />
      </q-td>
    </template>
    <template v-slot:body-cell-PlatformDRM="props">
      <q-td :props="props">
        <div v-html="props.value"></div>
      </q-td>
    </template>
    <template v-slot:body-cell-VaultImageURL="props">
      <q-td :props="props">
        <q-img :src="props.value" />
      </q-td>
    </template>
    <template v-slot:body-cell-Logo="props">
      <q-td :props="props">
        <span class="keyProviderImage">
          <q-img :src="props.value" />
        </span>
      </q-td>
    </template>
    <template v-slot:body-cell-Action_ConnectProduct="props">
      <q-td :props="props" v-if="props.value">
        <q-toggle
          v-model="props.row.IsConnected"
          checked-icon="check"
          color="green"
          unchecked-icon="clear"
          @click="callToActionButtonParent(props.row, 'connected')"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-Action_DisconnectProduct="props">
      <q-td :props="props" v-if="props.value">
        <q-toggle
          v-model="props.row.IsConnected"
          checked-icon="check"
          color="green"
          unchecked-icon="clear"
          @click="callToActionButtonParent(props.row, 'disConnected')"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-Connected="props">
      <q-td :props="props">
        <q-icon class="red" name="circle" v-if="props.value === '0'" />
        <q-icon class="green" name="circle" v-if="props.value === '1'" />
      </q-td>
    </template>
    <template v-slot:body-cell-Active="props">
      <q-td :props="props">
        <q-icon class="red" name="circle" v-if="props.value === 'False'" />
        <q-icon class="green" name="circle" v-if="props.value === 'True'" />
      </q-td>
    </template>
    <template v-slot:body-cell-SKU="props">
      <q-td :props="props">
        <SkuCard :skuData="props.value" :rowData="props.row" />
      </q-td>
    </template>
    <template v-slot:body-cell-actions="props" v-if="actionsCell != false">
      <q-td :props="props">
        <q-btn-dropdown color="primary" icon="more_vert">
          <q-list class="table-actions">
            <q-item
              v-close-popup
              v-if="componentName"
              :to="{
                name: componentName,
                params: { id: props.row.ID },
              }"
              data-cy="filterTableDetail-button"
            >
              <q-item-section>
                <q-item-label>
                  <q-icon name="edit" />
                  {{ $t("tablePager.details") }}
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              clickable
              v-close-popup
              v-on:click.stop="sendPropToParent(props.row)"
              v-if="!componentName"
              data-cy="filterTableDetail-button"
            >
              <q-item-section>
                <q-item-label>
                  <q-icon name="edit" />
                  {{ $t("tablePager.edit") }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-td>
    </template>
    <template v-slot:pagination>
      <span class="pager-info">
        {{ pagerInfo }}{{ $t("of.definition") }} {{ _pagination.rowsNumber }}
      </span>
      <q-btn
        icon="chevron_left"
        round
        dense
        flat
        :disable="_pagination.page <= 1"
        @click="prevPage"
        data-cy="filterTablePreviousPage-button"
      />
      <q-btn
        icon="chevron_right"
        round
        dense
        flat
        :disable="_pagination.page >= _pagination.rowsNumber / _pagination.rowsPerPage"
        @click="nextPage"
        data-cy="filterTableNextPage-button"
      />
    </template>
  </q-table>
</template>
