import { defineComponent, ref, onMounted } from "vue";
import Navigation from "@/components/navigation/Navigation.vue";
import store from "@/store";

export default defineComponent({
  name: "Drawer",
  components: {
    Navigation,
  },

  setup() {
    const leftDrawerOpen = ref(true);
    const chevronChoice = ref("chevron_left");

    onMounted(() => {
      checkLeftDrawer();
    })

    function clickleftDrawer() {
      leftDrawerOpen.value = !store.state.drawer.open;
      store.dispatch("toggleDrawer");
      if (leftDrawerOpen.value == true) {
        chevronChoice.value = "chevron_left";
      } else {
        chevronChoice.value = "chevron_right";
      }
    }

    function checkLeftDrawer() {
      leftDrawerOpen.value = store.state.drawer.open;
      if (leftDrawerOpen.value == true) {
        chevronChoice.value = "chevron_left";
      } else {
        chevronChoice.value = "chevron_right";
      }
    }

    return {
      leftDrawerOpen,
      chevronChoice,
      checkLeftDrawer,
      clickleftDrawer
    }
  },
})

