export function generateFormFilterArray(arr) {
  let newArr = [];
  let additionalFilterPrefix = "FilterAdditional_";
  let defaultValue = [];

  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].fields.length; j++) {
      if (arr[i].fields[j].vueType == 11 && arr[i].fields[j].options) {
        let newOptions = [];
        if (arr[i].fields[j].propertyName.includes(additionalFilterPrefix)) {
          for (let k = 0; k < arr[i].fields[j].options.length; k++) {
            newOptions.push({
              label: arr[i].fields[j].options[k].text,
              value: arr[i].fields[j].options[k].value
            });
          }
          newArr.push({
            propertyName: arr[i].fields[j].propertyName,
            isReadOnly: arr[i].fields[j].isReadOnly,
            isMandatory: arr[i].fields[j].isMandatory,
            isHidden: arr[i].fields[j].isHidden,
            value: arr[i].fields[j].value,
            options: newOptions,
            title: arr[i].fields[j].title,
            vueType: arr[i].fields[j].vueType,
            additionalFilter: "True"
          });
        } else {
          for (let k = 0; k < arr[i].fields[j].options.length; k++) {
            newOptions.push({
              label: arr[i].fields[j].options[k].text,
              value: arr[i].fields[j].options[k].value
            });
          }
          newArr.push({
            propertyName: arr[i].fields[j].propertyName,
            isReadOnly: arr[i].fields[j].isReadOnly,
            isMandatory: arr[i].fields[j].isMandatory,
            isHidden: arr[i].fields[j].isHidden,
            value: arr[i].fields[j].value,
            options: newOptions,
            title: arr[i].fields[j].title,
            vueType: arr[i].fields[j].vueType,
            additionalFilter: "False"
          });
        }
      } else if (arr[i].fields[j].vueType == 7 && arr[i].fields[j].options) {
        if (arr[i].fields[j].propertyName.includes(additionalFilterPrefix)) {
          if (arr[i].fields[j].value === "") {
            newArr.push({
              propertyName: arr[i].fields[j].propertyName,
              isReadOnly: arr[i].fields[j].isReadOnly,
              isMandatory: arr[i].fields[j].isMandatory,
              isHidden: arr[i].fields[j].isHidden,
              value: defaultValue[j],
              options: arr[i].fields[j].options,
              title: arr[i].fields[j].title,
              vueType: arr[i].fields[j].vueType,
              additionalFilter: "True"
            });
          } else {
            newArr.push({
              propertyName: arr[i].fields[j].propertyName,
              isReadOnly: arr[i].fields[j].isReadOnly,
              isMandatory: arr[i].fields[j].isMandatory,
              isHidden: arr[i].fields[j].isHidden,
              value: arr[i].fields[j].value,
              options: arr[i].fields[j].options,
              title: arr[i].fields[j].title,
              vueType: arr[i].fields[j].vueType,
              additionalFilter: "True"
            });
          }
        } else {
          if (arr[i].fields[j].value === "") {
            newArr.push({
              propertyName: arr[i].fields[j].propertyName,
              isReadOnly: arr[i].fields[j].isReadOnly,
              isMandatory: arr[i].fields[j].isMandatory,
              isHidden: arr[i].fields[j].isHidden,
              value: defaultValue[j],
              options: arr[i].fields[j].options,
              title: arr[i].fields[j].title,
              vueType: arr[i].fields[j].vueType,
              additionalFilter: "False"
            });
          } else {
            let valueArr = arr[i].fields[j].value.split(',');
            //value: arr[i].fields[j].value,
            newArr.push({
              propertyName: arr[i].fields[j].propertyName,
              isReadOnly: arr[i].fields[j].isReadOnly,
              isMandatory: arr[i].fields[j].isMandatory,
              isHidden: arr[i].fields[j].isHidden,
              value: valueArr,
              options: arr[i].fields[j].options,
              title: arr[i].fields[j].title,
              vueType: arr[i].fields[j].vueType,
              additionalFilter: "False"
            });
          }
        }
      } else {
        if (arr[i].fields[j].propertyName.includes(additionalFilterPrefix)) {
          if (arr[i].fields[j].options) {
            for (let l = 0; l < arr[i].fields[j].options.length; l++) {
              if (arr[i].fields[j].options[l].value === "-1") {
                arr[i].fields[j].options.splice(l, 1);
                if (arr[i].fields[j].value === "" || arr[i].fields[j].value === "-1") {
                  arr[i].fields[j].value = "";
                }
              }
            }
          }
          newArr.push({
            propertyName: arr[i].fields[j].propertyName,
            isReadOnly: arr[i].fields[j].isReadOnly,
            isMandatory: arr[i].fields[j].isMandatory,
            isHidden: arr[i].fields[j].isHidden,
            value: arr[i].fields[j].value,
            options: arr[i].fields[j].options,
            title: arr[i].fields[j].title,
            vueType: arr[i].fields[j].vueType,
            additionalFilter: "True"
          });
        } else {
          if (arr[i].fields[j].options) {
            for (let l = 0; l < arr[i].fields[j].options.length; l++) {
              if (arr[i].fields[j].options[l].value === "-1") {
                arr[i].fields[j].options.splice(l, 1);
                if (arr[i].fields[j].value === "" || arr[i].fields[j].value === "-1") {
                  arr[i].fields[j].value = "";
                }
              }
            }
          }
          newArr.push({
            propertyName: arr[i].fields[j].propertyName,
            isReadOnly: arr[i].fields[j].isReadOnly,
            isMandatory: arr[i].fields[j].isMandatory,
            isHidden: arr[i].fields[j].isHidden,
            value: arr[i].fields[j].value,
            options: arr[i].fields[j].options,
            title: arr[i].fields[j].title,
            vueType: arr[i].fields[j].vueType,
            additionalFilter: "False"
          });
        }
      }
    }
  }
  return newArr;
}
