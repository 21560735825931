import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "keyProviderImage" }
const _hoisted_7 = { class: "pager-info" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "keyProviderImage" }
const _hoisted_14 = { class: "pager-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_SkuCard = _resolveComponent("SkuCard")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ((_ctx.tableName == 'dataTable' || _ctx.tableName == null) && _ctx.rows && _ctx.cols)
      ? (_openBlock(), _createBlock(_component_q_table, {
          key: 0,
          loading: _ctx.loading,
          rows: _ctx.rows,
          columns: _ctx.cols,
          pagination: _ctx._pagination,
          flat: "",
          bordered: "",
          "row-key": "name",
          class: "relative-position vltn-table custom-colored",
          onRowClick: _ctx.rowClick,
          "rows-per-page-options": [0]
        }, _createSlots({
          "body-cell-Name": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "font-weight-600",
                  innerHTML: props.value
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Available": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "font-weight-600",
                  innerHTML: props.value
                }, null, 8, _hoisted_2)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Title": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "font-weight-600 dark-blue",
                  innerHTML: props.value
                }, null, 8, _hoisted_3)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Vault": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "font-weight-600 dark-blue",
                  innerHTML: props.value
                }, null, 8, _hoisted_4)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-PackShotImageURL": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              class: "width-80"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_img, {
                  src: props.value
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-PlatformDRM": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: props.value
                }, null, 8, _hoisted_5)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-VaultImageURL": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_img, {
                  src: props.value
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Logo": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_q_img, {
                    src: props.value
                  }, null, 8, ["src"])
                ])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Action_ConnectProduct": _withCtx((props) => [
            (props.value)
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: 0,
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_toggle, {
                      modelValue: props.row.IsConnected,
                      "onUpdate:modelValue": ($event: any) => ((props.row.IsConnected) = $event),
                      "checked-icon": "check",
                      color: "green",
                      "unchecked-icon": "clear",
                      onClick: ($event: any) => (_ctx.callToActionButtonParent(props.row, 'connected')),
                      "data-cy": "myVaultProductsConnectProduct-button"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true)
          ]),
          "body-cell-Action_DisconnectProduct": _withCtx((props) => [
            (props.value)
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: 0,
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_toggle, {
                      modelValue: props.row.IsConnected,
                      "onUpdate:modelValue": ($event: any) => ((props.row.IsConnected) = $event),
                      "checked-icon": "check",
                      color: "green",
                      "unchecked-icon": "clear",
                      onClick: ($event: any) => (_ctx.callToActionButtonParent(props.row, 'disConnected')),
                      "data-cy": "myVaultProductsDisconnectProduct-button"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true)
          ]),
          "body-cell-Connected": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (props.value === '0')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      class: "red",
                      name: "circle"
                    }))
                  : _createCommentVNode("", true),
                (props.value === '1')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 1,
                      class: "green",
                      name: "circle"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Active": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (props.value === 'False')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      class: "red",
                      name: "circle"
                    }))
                  : _createCommentVNode("", true),
                (props.value === 'True')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 1,
                      class: "green",
                      name: "circle"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-SKU": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_SkuCard, {
                  skuData: props.value,
                  rowData: props.row
                }, null, 8, ["skuData", "rowData"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          pagination: _withCtx(() => [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.pagerInfo) + _toDisplayString(_ctx.$t("of.definition")) + " " + _toDisplayString(_ctx._pagination.rowsNumber), 1),
            _createVNode(_component_q_btn, {
              icon: "chevron_left",
              round: "",
              dense: "",
              flat: "",
              disable: _ctx._pagination.page <= 1,
              onClick: _ctx.prevPage,
              "data-cy": "commonTablePreviousPage-button"
            }, null, 8, ["disable", "onClick"]),
            _createVNode(_component_q_btn, {
              icon: "chevron_right",
              round: "",
              dense: "",
              flat: "",
              disable: _ctx._pagination.page >= _ctx._pagination.rowsNumber / _ctx._pagination.rowsPerPage,
              onClick: _ctx.nextPage,
              "data-cy": "commonTableNextPage-button"
            }, null, 8, ["disable", "onClick"])
          ]),
          _: 2
        }, [
          (_ctx.actionsCell != false)
            ? {
                name: "body-cell-actions",
                fn: _withCtx((props) => [
                  _createVNode(_component_q_td, { props: props }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn_dropdown, {
                        color: "primary",
                        icon: "more_vert"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_list, { class: "table-actions" }, {
                            default: _withCtx(() => [
                              (_ctx.componentName)
                                ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                    key: 0,
                                    to: {
                name: _ctx.componentName,
                params: { id: props.row.ID },
              },
                                    "data-cy": "commonTableDetail-button"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_label, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_icon, { name: "edit" }),
                                              _createTextVNode(" " + _toDisplayString(_ctx.$t("tablePager.details")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["to"])), [
                                    [_directive_close_popup]
                                  ])
                                : _createCommentVNode("", true),
                              (!_ctx.componentName)
                                ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                    key: 1,
                                    clickable: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.sendPropToParent(props.row)), ["stop"]),
                                    "data-cy": "commonTableDetail-button"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_label, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_icon, { name: "edit" }),
                                              _createTextVNode(" " + _toDisplayString(_ctx.$t("tablePager.edit")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])), [
                                    [_directive_close_popup]
                                  ])
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["loading", "rows", "columns", "pagination", "onRowClick"]))
      : _createCommentVNode("", true),
    (_ctx.tableName == 'filterTable' && _ctx.filteredDataArr && _ctx.cols)
      ? (_openBlock(), _createBlock(_component_q_table, {
          key: 1,
          loading: _ctx.loading,
          class: "vltn-table custom-colored",
          rows: _ctx.filteredDataArr,
          columns: _ctx.cols,
          pagination: _ctx._pagination,
          onRowClick: _ctx.rowClick,
          "rows-per-page-options": [0],
          flat: "",
          bordered: ""
        }, _createSlots({
          "body-cell-Name": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "font-weight-600",
                  innerHTML: props.value
                }, null, 8, _hoisted_8)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Available": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "font-weight-600",
                  innerHTML: props.value
                }, null, 8, _hoisted_9)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Title": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "font-weight-600 dark-blue",
                  innerHTML: props.value
                }, null, 8, _hoisted_10)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Vault": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "font-weight-600 dark-blue",
                  innerHTML: props.value
                }, null, 8, _hoisted_11)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-PackShotImageURL": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              class: "width-80"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_img, {
                  src: props.value
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-PlatformDRM": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: props.value
                }, null, 8, _hoisted_12)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-VaultImageURL": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_img, {
                  src: props.value
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Logo": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_13, [
                  _createVNode(_component_q_img, {
                    src: props.value
                  }, null, 8, ["src"])
                ])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Action_ConnectProduct": _withCtx((props) => [
            (props.value)
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: 0,
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_toggle, {
                      modelValue: props.row.IsConnected,
                      "onUpdate:modelValue": ($event: any) => ((props.row.IsConnected) = $event),
                      "checked-icon": "check",
                      color: "green",
                      "unchecked-icon": "clear",
                      onClick: ($event: any) => (_ctx.callToActionButtonParent(props.row, 'connected'))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true)
          ]),
          "body-cell-Action_DisconnectProduct": _withCtx((props) => [
            (props.value)
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: 0,
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_toggle, {
                      modelValue: props.row.IsConnected,
                      "onUpdate:modelValue": ($event: any) => ((props.row.IsConnected) = $event),
                      "checked-icon": "check",
                      color: "green",
                      "unchecked-icon": "clear",
                      onClick: ($event: any) => (_ctx.callToActionButtonParent(props.row, 'disConnected'))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : _createCommentVNode("", true)
          ]),
          "body-cell-Connected": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (props.value === '0')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      class: "red",
                      name: "circle"
                    }))
                  : _createCommentVNode("", true),
                (props.value === '1')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 1,
                      class: "green",
                      name: "circle"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-Active": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (props.value === 'False')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      class: "red",
                      name: "circle"
                    }))
                  : _createCommentVNode("", true),
                (props.value === 'True')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 1,
                      class: "green",
                      name: "circle"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-SKU": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_SkuCard, {
                  skuData: props.value,
                  rowData: props.row
                }, null, 8, ["skuData", "rowData"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          pagination: _withCtx(() => [
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.pagerInfo) + _toDisplayString(_ctx.$t("of.definition")) + " " + _toDisplayString(_ctx._pagination.rowsNumber), 1),
            _createVNode(_component_q_btn, {
              icon: "chevron_left",
              round: "",
              dense: "",
              flat: "",
              disable: _ctx._pagination.page <= 1,
              onClick: _ctx.prevPage,
              "data-cy": "filterTablePreviousPage-button"
            }, null, 8, ["disable", "onClick"]),
            _createVNode(_component_q_btn, {
              icon: "chevron_right",
              round: "",
              dense: "",
              flat: "",
              disable: _ctx._pagination.page >= _ctx._pagination.rowsNumber / _ctx._pagination.rowsPerPage,
              onClick: _ctx.nextPage,
              "data-cy": "filterTableNextPage-button"
            }, null, 8, ["disable", "onClick"])
          ]),
          _: 2
        }, [
          (_ctx.actionsCell != false)
            ? {
                name: "body-cell-actions",
                fn: _withCtx((props) => [
                  _createVNode(_component_q_td, { props: props }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn_dropdown, {
                        color: "primary",
                        icon: "more_vert"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_list, { class: "table-actions" }, {
                            default: _withCtx(() => [
                              (_ctx.componentName)
                                ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                    key: 0,
                                    to: {
                name: _ctx.componentName,
                params: { id: props.row.ID },
              },
                                    "data-cy": "filterTableDetail-button"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_label, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_icon, { name: "edit" }),
                                              _createTextVNode(" " + _toDisplayString(_ctx.$t("tablePager.details")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["to"])), [
                                    [_directive_close_popup]
                                  ])
                                : _createCommentVNode("", true),
                              (!_ctx.componentName)
                                ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                    key: 1,
                                    clickable: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.sendPropToParent(props.row)), ["stop"]),
                                    "data-cy": "filterTableDetail-button"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_label, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_icon, { name: "edit" }),
                                              _createTextVNode(" " + _toDisplayString(_ctx.$t("tablePager.edit")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])), [
                                    [_directive_close_popup]
                                  ])
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["loading", "rows", "columns", "pagination", "onRowClick"]))
      : _createCommentVNode("", true)
  ], 64))
}