import { defineComponent, ref, Ref } from "vue";

export default defineComponent({
    name: "DropDownButton",
    props: ["properties"],
    setup(props) {
        const showList: Ref<boolean> = ref(false)
        const property = ref(props.properties)
        return {
            property,
            showList,
        }
    }
})

