<style lang="scss" src="./FabButton.scss"></style>
<script lang="ts" src="./FabButton.ts"></script>

<template>
  <div class="fabbutton-div">
    <q-fab direction="left" padding="sm" class="shadow-up-4 items-center">
      <template v-slot:icon>
        <q-icon class="icons" name="keyboard_arrow_left" />
      </template>
      <template v-slot:active-icon>
        <q-icon class="icons" name="more_vert" />
      </template>
      <DropDownButton
        v-if="isGoogle"
        :properties="dropDownProperties[0]"
        class="q-ml-md"
      >
        <template #dropDownGoogle>
          <slot name="dropDownGoogle" />
        </template>
      </DropDownButton>
      <DropDownButton
        v-if="isAction"
        :properties="dropDownProperties[1]"
      >
        <template #dropDownAction>
          <slot name="dropDownAction" />
        </template>
      </DropDownButton>
      <slot />
    </q-fab>
  </div>
</template>