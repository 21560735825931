import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fabbutton-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_DropDownButton = _resolveComponent("DropDownButton")!
  const _component_q_fab = _resolveComponent("q-fab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_fab, {
      direction: "left",
      padding: "sm",
      class: "shadow-up-4 items-center"
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_q_icon, {
          class: "icons",
          name: "keyboard_arrow_left"
        })
      ]),
      "active-icon": _withCtx(() => [
        _createVNode(_component_q_icon, {
          class: "icons",
          name: "more_vert"
        })
      ]),
      default: _withCtx(() => [
        (_ctx.isGoogle)
          ? (_openBlock(), _createBlock(_component_DropDownButton, {
              key: 0,
              properties: _ctx.dropDownProperties[0],
              class: "q-ml-md"
            }, {
              dropDownGoogle: _withCtx(() => [
                _renderSlot(_ctx.$slots, "dropDownGoogle")
              ]),
              _: 3
            }, 8, ["properties"]))
          : _createCommentVNode("", true),
        (_ctx.isAction)
          ? (_openBlock(), _createBlock(_component_DropDownButton, {
              key: 1,
              properties: _ctx.dropDownProperties[1]
            }, {
              dropDownAction: _withCtx(() => [
                _renderSlot(_ctx.$slots, "dropDownAction")
              ]),
              _: 3
            }, 8, ["properties"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ]))
}