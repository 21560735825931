<style lang="scss" scoped src="./FormsFilter.scss"></style>
<script lang="ts" src="./FormsFilter.ts"></script>

<template>
  <div class="justify-end row top-filter width-100-perc" v-if="formElements.length">
    <div
      v-for="element in formElements.filter((t) => !t.isDialog)"
      :key="element.propertyName"
      class="col q-ml-sm"
    >
      <q-form @submit="filter()" class="form-container height-100-perc width-100-perc">
        <q-input
          v-if="
            element.vueType == 5 &&
            element.propertyName != undefined &&
            element.isReadOnly == false &&
            !element.isHidden
          "
          rounded
          outlined
          type="text"
          :ref="element.propertyName"
          v-model="element.value"
          :label="element.title"
          lazy-rules
          :disable="element.isReadOnly"
          :rules="element.isMandatory ? [rules.required] : []"
          @update:model-value="enableApplyButton(element.propertyName)"
          :data-cy="element.propertyName + '-input'"
        >
          <template
            v-if="
              element.propertyName === 'Filter_Search' ||
              element.propertyName === 'Filter_Product' ||
              element.propertyName === 'FilterText'
            "
            v-slot:append
          >
            <q-icon class="mid-blue" name="search" />
          </template>
        </q-input>
        <q-input
          v-if="
            element.vueType == 6 &&
            element.propertyName != undefined &&
            element.isReadOnly == false &&
            !element.isHidden
          "
          rounded
          outlined
          type="text"
          :ref="element.propertyName"
          v-model="element.value"
          :label="element.title"
          lazy-rules
          class="read-only-field"
          :disable="element.isReadOnly"
          :rules="element.isMandatory ? [rules.required] : []"
          @update:model-value="enableApplyButton(element.propertyName)"
          :data-cy="element.propertyName + '-input'"
        />
        <q-input
          v-if="
            element.vueType == 13 &&
            element.propertyName != undefined &&
            !element.isHidden
          "
          rounded
          outlined
          :ref="element.propertyName"
          v-model="element.value"
          :label="element.title"
          :disable="element.isReadOnly"
          :rules="element.isMandatory ? [rules.required] : []"
          :data-cy="element.propertyName + '-input'"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date
                  :options="
                    element.propertyName === 'Filter_EndDate'
                      ? (date) => date >= endDateAutoLimit(date)
                      : (date) => date <= startDateAutoLimit(date)
                  "
                  v-model="element.value"
                  mask="DD/MM/YYYY"
                  @update:model-value="enableApplyButton(element.propertyName)"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-select
          v-if="
            element.vueType == 2 && element.propertyName != undefined && !element.isHidden
          "
          class="background-blue"
          rounded
          outlined
          :ref="element.propertyName"
          v-model="element.value"
          :options="element.options"
          :label="element.title"
          option-value="value"
          option-label="text"
          emit-value
          map-options
          :disable="element.isReadOnly"
          :rules="element.isMandatory ? [rules.required] : []"
          @update:model-value="enableApplyButton(element.propertyName)"
          :data-cy="element.propertyName + '-select'"
        />
        <q-select
          v-if="
            element.vueType == 7 &&
            element.propertyName != undefined &&
            element.value === '' &&
            !element.isHidden
          "
          class="background-blue"
          rounded
          outlined
          :ref="element.propertyName"
          v-model="element.value"
          multiple
          :options="element.options"
          use-chips
          stack-label
          :label="element.title"
          lazy-rules
          option-value="value"
          option-label="text"
          emit-value
          map-options
          input-debounce="0"
          :disable="element.isReadOnly"
          :rules="element.isMandatory ? [rules.required] : []"
          @update:model-value="enableApplyButton(element.propertyName)"
          :data-cy="element.propertyName + '-select'"
        />
        <q-select
          v-if="
            element.vueType == 7 &&
            element.propertyName != undefined &&
            element.value !== '' &&
            !element.isHidden
          "
          class="background-blue"
          rounded
          outlined
          :ref="element.propertyName"
          v-model="element.value"
          multiple
          :options="element.options"
          use-chips
          stack-label
          :label="element.title"
          lazy-rules
          option-value="value"
          option-label="text"
          emit-value
          map-options
          :disable="element.isReadOnly"
          :rules="element.isMandatory ? [rules.required] : []"
          @update:model-value="enableApplyButton(element.propertyName)"
          :data-cy="element.propertyName + '-select'"
        />
        <label
          class="label top-minus-6"
          v-if="
            element.vueType == 11 &&
            element.propertyName != undefined &&
            !element.isHidden
          "
          >{{ element.title }}</label
        >
        <q-option-group
          v-if="
            element.vueType == 11 &&
            element.propertyName != undefined &&
            !element.isHidden
          "
          class="margin-top-minus-12"
          :options="element.options"
          inline
          :ref="element.propertyName"
          v-model="element.value"
          :disable="element.isReadOnly"
          :rules="element.isMandatory ? [rules.required] : []"
          @update:model-value="enableApplyButton(element.propertyName)"
          :data-cy="element.propertyName + '-option'"
        />
      </q-form>
    </div>
    <div class="col-auto q-mx-md">
      <q-btn
        class="default-btn"
        flat
        :label="$t('apply.definition')"
        icon="filter_alt"
        :disable="disable"
        @click="filter()"
        data-cy="filterFilterButton-button"
      />
    </div>
    <div
      v-if="formElements.filter((t) => t.isDialog).length > 1"
      class="q-px-md border-filter__left"
    >
      <q-btn
        flat
        class="q-px-none"
        @click="showDialog = true"
        data-cy="filterOpenFilterDialog-button"
      >
        <div class="row">
          <div class="col-12 flex flex-center">
            <font-awesome-icon class="mid-blue" icon="far fa-filter" />
          </div>
          <div class="col-12 flex flex-center">
            <span class="mid-blue">
              {{ $t("filters.definition") }}
            </span>
          </div>
        </div>
      </q-btn>
    </div>
  </div>

  <q-dialog
    v-if="formElements.filter((t) => t.isDialog).length > 0"
    v-model="showDialog"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="small-modal__container q-page q-page-container q-pl-none">
      <q-form @submit="filter()" class="form-container height-100-perc width-100-perc">
        <h5 class="q-mt-md q-mb-none q-py-none q-ml-md">
          {{ $t("filterOptions.definition") }}
        </h5>
        <div class="row justify-center q-my-md top-filter">
          <div
            class="col-10 q-my-xs q-form"
            v-for="element in formElements.filter((t) => t.isDialog)"
            :key="element.propertyName"
          >
            <q-input
              v-if="
                element.vueType == 5 &&
                element.propertyName != undefined &&
                element.isReadOnly == false &&
                !element.isHidden
              "
              dense
              rounded
              outlined
              type="text"
              :ref="element.propertyName"
              v-model="element.value"
              :label="element.title"
              lazy-rules
              :disable="element.isReadOnly"
              :rules="element.isMandatory ? [rules.required] : []"
              @update:model-value="enableApplyButton(element.propertyName)"
              :data-cy="element.propertyName + '-input'"
            />
            <q-input
              v-if="
                element.vueType == 6 &&
                element.propertyName != undefined &&
                element.isReadOnly == false &&
                !element.isHidden
              "
              dense
              rounded
              outlined
              type="text"
              :ref="element.propertyName"
              v-model="element.value"
              :label="element.title"
              lazy-rules
              class="read-only-field"
              :disable="element.isReadOnly"
              :rules="element.isMandatory ? [rules.required] : []"
              @update:model-value="enableApplyButton(element.propertyName)"
              :data-cy="element.propertyName + '-input'"
            />
            <q-input
              v-if="
                element.vueType == 13 &&
                element.propertyName != undefined &&
                !element.isHidden
              "
              dense
              rounded
              outlined
              :ref="element.propertyName"
              v-model="element.value"
              :label="element.title"
              :disable="element.isReadOnly"
              :rules="element.isMandatory ? [rules.required] : []"
              @update:model-value="enableApplyButton(element.propertyName)"
              :data-cy="element.propertyName + '-input'"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="element.value" mask="DD/MM/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <q-select
              v-if="
                element.vueType == 2 &&
                element.propertyName != undefined &&
                !element.isHidden
              "
              dense
              class="background-blue"
              rounded
              outlined
              :ref="element.propertyName"
              v-model="element.value"
              :options="element.options"
              :label="element.title"
              option-value="value"
              option-label="text"
              emit-value
              map-options
              :disable="element.isReadOnly"
              :rules="element.isMandatory ? [rules.required] : []"
              @update:model-value="enableApplyButton(element.propertyName)"
              :data-cy="element.propertyName + '-select'"
            />
            <q-select
              v-if="
                element.vueType == 7 &&
                element.propertyName != undefined &&
                element.value === '' &&
                !element.isHidden
              "
              dense
              class="background-blue"
              rounded
              outlined
              :ref="element.propertyName"
              v-model="element.value"
              multiple
              :options="element.options"
              use-chips
              stack-label
              :label="element.title"
              lazy-rules
              option-value="value"
              option-label="text"
              emit-value
              map-options
              input-debounce="0"
              :disable="element.isReadOnly"
              :rules="element.isMandatory ? [rules.required] : []"
              @update:model-value="enableApplyButton(element.propertyName)"
              :data-cy="element.propertyName + '-select'"
            />
            <q-select
              v-if="
                element.vueType == 7 &&
                element.propertyName != undefined &&
                element.value !== '' &&
                !element.isHidden
              "
              dense
              class="background-blue"
              rounded
              outlined
              :ref="element.propertyName"
              v-model="element.value"
              multiple
              :options="element.options"
              use-chips
              stack-label
              :label="element.title"
              lazy-rules
              option-value="value"
              option-label="text"
              emit-value
              map-options
              :disable="element.isReadOnly"
              :rules="element.isMandatory ? [rules.required] : []"
              @update:model-value="enableApplyButton(element.propertyName)"
              :data-cy="element.propertyName + '-select'"
            />
            <label
              class="label top-minus-6"
              v-if="
                element.vueType == 11 &&
                element.propertyName != undefined &&
                !element.isHidden
              "
            >
              {{ element.title }}
            </label>
            <q-option-group
              v-if="
                element.vueType == 11 &&
                element.propertyName != undefined &&
                !element.isHidden
              "
              dense
              class="margin-top-minus-12"
              :options="element.options"
              inline
              :ref="element.propertyName"
              v-model="element.value"
              :disable="element.isReadOnly"
              :rules="element.isMandatory ? [rules.required] : []"
              @update:model-value="enableApplyButton(element.propertyName)"
              :data-cy="element.propertyName + '-option'"
            />
          </div>
        </div>
        <q-card-actions align="right">
          <q-btn
            dense
            flat
            color="primary"
            :label="$t('filterCancel.definition')"
            @click="(showDialog = false), clearFilter()"
            data-cy="filterCloseFilterDialog-button"
          />
          <q-btn
            dense
            flat
            color="primary"
            :label="$t('filterApply.definition')"
            type="submit"
            @click="showDialog = false"
            data-cy="filterApplyFilterDialog-button"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>