<style lang="scss" scoped src="./MediaDeviceNotSupported.scss"></style>
<script lang="ts" src="./MediaDeviceNotSupported.ts"></script>

<template>
  <div class="media-device-not-supported-container">
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <div class="row">
          <div class="col-12 margin-top-100">
            <img
              src="../../assets/images/vaultN-logo.svg"
            />
          </div>
          <div class="col-12 q-mt-xl">
            <div class="media-device-not-supported-ellipse">
              <p class="text1">{{ $t("login.screenResolutionText1") }}</p>
              <p class="text2">{{ $t("login.screenResolutionText2") }}</p>
              <p class="text3">{{ $t("login.screenResolutionText3") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</template>