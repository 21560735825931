import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { appImgSrcUrl } from "@/extensions/extra";
import store from "@/store";

export default class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: false,
      headers: {
        "Content-Security-Policy": "img-src " + appImgSrcUrl,
      },
    });
  }

  async axiosCall<T>(config: AxiosRequestConfig) {
    try {
      store.state.loadingSpinner = true;
      const { data }: AxiosResponse =
        await this.axiosInstance.request<T>(config);
      store.state.loadingSpinner = false;
      return [null, data];
    } catch (error) {
      store.state.loadingSpinner = false;
      return [error, null];
    }
  }
}

export const service = new ApiService();
