<style lang="scss" src="./TopMenu.scss"></style>
<script lang="ts" src="./TopMenu.ts"></script>

<template>
  <div class="top-navigation">
    <div v-for="item in menu" :key="item.id" class="float-left margin-right-20">
      <q-btn
        v-if="item.id == highlightedIndex"
        color="primary"
        style="color: #2aa1e8 !important; font-weight: 600 !important"
        :label="item.text"
        class="highlighted"
        :to="item.href"
      >
      </q-btn>
      <q-btn v-else color="primary" :label="item.text" :to="item.href"> </q-btn>
    </div>
  </div>
</template>
