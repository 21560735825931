<style lang="scss" src="./Breadcrumbs.scss"></style>
<script lang="ts" src="./Breadcrumbs.ts"></script>

<template>
  <q-breadcrumbs>
    <template v-slot:separator>
      <font-awesome-icon
        size="sm"
        icon="fal fa-angle-right"
        class="q-pt-xs blue"
      />
    </template>
    <q-breadcrumbs-el
      v-for="element in data"
      :key="element"
      :label="element.lang ? $t(element.title) : element.title"
      :to="element.path"
      class="breadcrumbs text-h3 text-weight-medium"
      :class="
        element.isDisable ? 'breadcrumbs__disable' : 'breadcrumbs__active'
      "
      :disable="element.isDisable"
    >
    </q-breadcrumbs-el>
    <!--
    <q-btn flat round color="primary" dense @click="getContent">
      <font-awesome-icon icon="fal fa-circle-question" class="height-20 blue" />
    </q-btn>
    -->
  </q-breadcrumbs>
  <Description
    v-model="state.dialogValues.description"
    :originalUrl="originalURL"
  />
</template>