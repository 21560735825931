import { defineComponent, ref, reactive } from "vue";
import Description from "@/components/dialogs/description/Description.vue";
import { currentSiteId } from "@/extensions/extra";
import { ContentModel } from "@/models/content";
import { GET_CONTENT } from "@/services/content-service"
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { returnErrors } from "@/utils/returnErrors";

export default defineComponent({
    name: "Breadcrumbs",
    props: ["BreadcrumbsData", "originalUrl"],
    components: {
        Description
    },

    setup(props) {
        const data = ref(props.BreadcrumbsData);
        const state = reactive({
            dialogValues: {
                description: false,
            }
        })
        const originalURL = ref(props.originalUrl);
        const contentModel = new ContentModel();
        const description = ref();
        const $q = useQuasar();

        async function getContent() {
            contentModel.currentSiteId = currentSiteId;
            contentModel.originalUrl = props.originalUrl;
            description.value = "";
            const [error, response] = await GET_CONTENT(contentModel)
            if (error) {
                const errorMessage: any = returnErrors(error);
                $q.notify({
                    color: 'negative',
                    group: false,
                    message: errorMessage,
                    position: 'top',
                    icon: 'report_problem'
                });
            } else {
                description.value = response.list.description;
                $q.notify({
                    group: false,
                    message: description.value,
                    icon: 'info',
                    color: 'blue',
                    position: 'top',
                    html: true,
                    timeout: 8000,
                })
            }
        }

        return {
            data,
            state,
            originalURL,
            getContent,
        }
    }
})

