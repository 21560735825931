import { defineComponent, ref, Ref, computed } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import i18n from "@/i18n";
import useClipboard from "@/utils/clipboard";
export default defineComponent({
  name: "SkuCard",
  props: ["skuData", "rowData"],

  setup(props) {
    const $q = useQuasar();
    const isCoppied: Ref<boolean> = ref(false);
    const isDialog: Ref<boolean> = ref(false);
    const checkedSKUs: Ref<any[]> = ref([]);
    const allArr: Ref<any[]> = ref([]);
    const { toClipboard } = useClipboard();
    const sku = computed(() => {
      const multipleSku = props.skuData?.split(",");
      if (multipleSku?.length > 1) {
        return multipleSku;
      } else {
        return props.skuData;
      }
    });

    function copySku() {
      toClipboard(props.skuData);
      isCoppied.value = true;
      setTimeout(function () {
        isCoppied.value = false;
      }, 800);
    }

    function multipleCopy() {
      let tempString = "";
      let index = 0;
      for (const t of checkedSKUs.value) {
        if (index + 1 === checkedSKUs.value.length) {
          tempString = tempString + t;
        } else {
          tempString = tempString + t + ", ";
        }
        index++;
      }
      setTimeout(function () {
        toClipboard(tempString);
      }, 100);
      $q.notify({
        group: false,
        color: "primary",
        message: i18n.global.t("sku.copied").toString(),
        position: "bottom",
        icon: "content_copy",
      });
      checkedSKUs.value = [];
      allArr.value = [];
    }

    function allChecked(value: any) {
      if (value.length > 0) {
        for (const t of value[0]) {
          checkedSKUs.value.push(t);
        }
      } else {
        checkedSKUs.value = [];
        allArr.value = [];
      }
    }

    function selectedCheck() {
      if (sku.value.length === checkedSKUs.value.length) {
        allArr.value.push(sku.value);
      } else {
        allArr.value = [];
      }
    }

    return {
      sku,
      isCoppied,
      isDialog,
      props,
      checkedSKUs,
      allArr,
      allChecked,
      copySku,
      multipleCopy,
      selectedCheck,
    };
  },
});
