import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import store from "@/store";
import signalR, { HubConnectionBuilder } from "@microsoft/signalr";
import i18n from "@/i18n";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useCookies } from "vue3-cookies";
import { signalrURL } from "@/extensions/extra";
import { useOidcStore } from "vue3-oidc";

export default defineComponent({
  name: "SignalR",

  setup() {
    const { state } = useOidcStore();
    const ownerGuid = computed(() => store.state.ownerGuid);
    const userId = computed(() => store.state.userId);
    const $q = useQuasar();
    const cookieValue = `${state.value.user?.access_token}`;

    onMounted(() => {
      const apiUrl = signalrURL;
      const connection = new HubConnectionBuilder()
        .withUrl(apiUrl + "hubs/notification", {
          accessTokenFactory: () => cookieValue,
        })
        .withAutomaticReconnect()
        .build();
      connection.start().then(() => {
        connection.send("attachToGroup", ownerGuid.value);
      });
      connection.on("keybykeyreportupdate", (data: any) => {
        if (data.ownerId == userId.value && data.message != null) {
          $q.notify({
            message: i18n.global
              .t("reportIsReadyToDownload.definition")
              .toString(),
            color: "blue",
            position: "top",
            icon: "information",
            timeout: 8000,
            group: "report-group",
            actions: [
              {
                label: i18n.global.t("download.definition").toString(),
                color: "white",
                handler: () => {
                  window.open(data.message, "_self");
                },
              },
            ],
          });
          store.commit("setIsReportReady", true);
        }
      });
      /*connection.on("pricelistgenerationupdate", (data: any) => {
        console.log("pricelistgenerationupdate");
        console.log(data);
        if (data.sender == ownerGuid.value && data.fileUrl != null) {
          $q.notify({
            message: i18n.global.t("priceListIsReadyToDownload.definition").toString(),
            color: "blue",
            position: "top",
            icon: "information",
            timeout: 8000,
            group: 'list-all-products-group',
            actions: [
              {
                label: i18n.global.t("download.detail").toString(), color: "white", handler: () => {
                  window.open(data.fileUrl, '_self');
                }
              }
            ]
          });
          store.commit('setIsReportReady', true);
        }
      });*/
    });
    return {};
  },
});
