import { computed, defineComponent, onMounted, ref } from "vue";
import i18n from "@/i18n";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { GET_TOP_NAVIGATION } from "@/services/top-navigation-service"
import { TopNavigationModel } from "@/models/topNavigation";
import { currentSiteId, originalUrl } from "@/extensions/extra";
import { returnErrors } from "@/utils/returnErrors";
import { checkCookie } from "@/utils/checkCookie";
import { useCookies } from "vue3-cookies";

export default defineComponent({
  name: "TopNavigationSettings",
  props: {
    items: {
      type: String,
      required: true,
      default: "mediakiwi",
    },
  },
  components: {},

  setup(props) {
    const $q = useQuasar();
    const topNavigationModel = new TopNavigationModel();
    const originalUrlValue = computed(
      () => `${props.items}`
    );
    const temp = ref([]);
    const groupType = ref("PROFILE");

    onMounted(() => {
      getTopNavigation();
    });

    async function getTopNavigation() {
      topNavigationModel.currentSiteId = currentSiteId;
      topNavigationModel.originalUrl = originalUrl;
      const [error, response] = await GET_TOP_NAVIGATION(topNavigationModel, groupType.value)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        temp.value = response.items[0].items;
      }
    }

    return {
      topNavigationModel,
      getTopNavigation,
      originalUrlValue,
      groupType,
      list: temp,
    }
  },

})

