import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createBlock(_component_q_list, { class: "navigation-qlist" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createBlock(_component_q_item, {
          clickable: "",
          tag: "a",
          href: item.href,
          key: item.id,
          class: _normalizeClass([item.isActive ? 'active' : ''])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { avatar: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: item.iconClass,
                  size: "lg",
                  class: "width-24"
                }, null, 8, ["icon"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_item_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.text), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["href", "class"]))
      }), 128))
    ]),
    _: 1
  }))
}