import i18n from "@/i18n";

export const int32MaxLimit = 2147483647;
export const int32MinLimit = -2147483648;

export default {
  rules: {
    required: (value: string | null | undefined) => {
      if (value == undefined || value == null || value === "") {
        return i18n.global.t('validationMessages.requiredField').toString();
      }
      return true;
    },
    minChars(value: string | any[], min: number) {
      if (!value) return true;
      if (value.length >= min) return true;
      return i18n.global.t('validationMessages.minChars').toString() + " " + min;
    },
    maxChars(value: string | any[], max: number) {
      if (!value) return true;
      if (value.length <= max) return true;
      return i18n.global.t('validationMessages.maxChars', { limit: max }).toString();
    },
    number: (value: any) => {
      if (!value) return true;
      if (!isNaN(Number(value))) return true;
      return i18n.global.t('validationMessages.mustBeNumber').toString();
    },
    alphanumeric: (value: string) => {
      const pattern = /^\w+$/
      return pattern.test(value) || i18n.global.t('validationMessages.mustBeAlphanumeric').toString();
    },
    isString: (value: string) => {
      const pattern = /^[a-zA-Z]/
      return pattern.test(value) || i18n.global.t('validationMessages.mustBeString').toString();
    },
    email: (value: string) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || i18n.global.t('validationMessages.enterValidEmail').toString();
    },
    phone: (value: string) => {
      const pattern = /(?<=\s|^)\d+(?=\s|$)/
      return pattern.test(value) || i18n.global.t('validationMessages.mustBePhoneNumber').toString();
    },
    password: (value: string) => {
      const pattern = /^(?=.*\d)(?=.*[!:;#$%^&.,='*])(?=.*[a-z])(?=.*[A-Z])/
      return pattern.test(value) || i18n.global.t('validationMessages.mustBePassword').toString();
    },
    integer: (value: any) => {
      if (!value) return true;
      if (!Number.isInteger(Number(value))) {
        return i18n.global.t('validationMessages.mustBeWholeNumber').toString();
      }
      // min max limit control for int32
      const num = Number(value);
      if (num < int32MinLimit || num > int32MaxLimit) {
        return i18n.global.t('validationMessages.mustBeBetween', { fromLimit: int32MinLimit, toLimit: int32MaxLimit }).toString();
      }
      return true;
    },
    int32Range: (value: any) => {
      if (!value) return true;
      const num = Number(value);
      if (num < int32MinLimit || num > int32MaxLimit) {
        return i18n.global.t('validationMessages.mustBeBetween', { fromLimit: int32MinLimit, toLimit: int32MaxLimit }).toString();
      }
      return true;
    },
    positive: (value: any) => {
      if (!value) return true;
      if (Number(value) >= 0) return true;
      return i18n.global.t('validationMessages.minValueIncluded', { limit: 0 }).toString();
    },
    minValue(value: any, minLimit: number) {
      if (!value) return true;
      if (Number(value) >= minLimit) return true;
      return i18n.global.t('validationMessages.minValue', { limit: minLimit }).toString();
    },
    maxValue(value: any, maxLimit: number) {
      if (!value) return true;
      if (Number(value) <= maxLimit) return true;
      return i18n.global.t('validationMessages.maxValue', { limit: maxLimit }).toString();
    },
    minArrayLength(value: string | any[], min: number) {
      if (value.length >= min) return true;
      return i18n.global.t('validationMessages.minArrayLength', { limit: min }).toString();
    },
    maxArrayLength(value: string | any[], max: number) {
      if (value.length <= max) return true;
      return i18n.global.t('validationMessages.maxArrayLength', { limit: max }).toString();
    },
    hasSameValue(array: string | any[], value: any, index: number) {
      if (!value) return true;
      for (let i = 0; i < array.length; i++) {
        if (i != index && array[i][0] == value)
          return i18n.global.t('validationMessages.alreadyIncludes', { value: value }).toString();
      }
      return true;
    },

    maxDecimals(value: any, maxLimit: number) {
      if (!value) return true;
      const countDecimals = function (num: number) {
        if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
        const split = String(num).split(".");
        if (split.length != 2) return 0;
        return split[1].length || 0;
      };
      if (countDecimals(value) <= maxLimit) return true;
      return i18n.global.t('validationMessages.maxDecimals', { value: maxLimit }).toString();
    },

    isBetween: (value: any, minLimit: number, maxLimit: number) => {
      if (!value) return true;
      const num = Number(value);
      if (num < minLimit || num > maxLimit) {
        return i18n.global.t('validationMessages.mustBeBetween', { fromLimit: minLimit, toLimit: maxLimit }).toString();
      }
      return true;
    },

    // :error-messages
    minMaxCheck(minValue: any, maxValue: any): string {
      const numMax = Number(maxValue);
      const numMin = Number(minValue);
      if (numMax <= numMin) {
        return i18n.global.t('validationMessages.minMaxError').toString();
      }
      return '';
    },

    dateMinMaxCheck(startDate: Date, endDate: Date): string {
      if (endDate <= startDate) {
        return i18n.global.t('validationMessages.dateMinMaxError').toString();
      }
      return '';
    },

    passwordMatchCheck(string1: string, string2: string): string {
      if (string1 !== string2 && string1 !== '' && string2 !== '') {
        return i18n.global.t('validationMessages.passwordsDoNotMatch').toString();
      }
      return '';
    },

    passwordDifferenceCheck(string1: string, string2: string): string {
      if (string1 === string2 && string1 !== '') {
        return i18n.global.t('validationMessages.passwordsCannotBeSame').toString();
      }
      return '';
    }
  },
};
