<style lang="scss" src="./TopNavigation.scss"></style>
<script lang="ts" src="./TopNavigation.ts"></script>

<template>
  <div class="top-navigation">
    <div
      v-for="item in list"
      :key="item.text"
      class="float-left margin-right-20"
    >
      <q-btn
        v-if="item.items.length > 0"
        color="primary"
        :label="item.text"
        :class="[item.isHighlighted ? 'highlighted' : '']"
      >
        <q-menu
          transition-show="jump-down"
          transition-hide="jump-up"
          class="q-btn-q-menu"
        >
          <q-list v-for="itemSub in item.items" :key="itemSub.text">
            <q-item clickable v-close-popup>
              <q-item-section>
                <router-link :to="itemSub.href">
                  {{ itemSub.text }}
                </router-link>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-btn
        v-if="item.items.length <= 0"
        color="primary"
        :label="item.text"
        :class="item.isHighlighted ? 'highlighted' : ''"
        :style="
          item.isHighlighted
            ? 'color: #2aa1e8 !important;font-weight: 600 !important;'
            : ''
        "
        :to="item.href"
      >
      </q-btn>
    </div>
  </div>
</template>
