import { RoleNameFields } from "@/base/core/enum";
import store from "@/store";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "TopMenu",
  props: {
    folder: {
      type: String,
      required: true,
    },
    highlightedIndex: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const menu = ref();
    const isRetailer = computed(() => store.state.ownerRole === RoleNameFields.retailer);

    onMounted(async () => {
      menu.value = (await import(`@/json/${props.folder}`)).default;

      // Temporary solution
      if (props.folder == 'my_vault' && isRetailer.value) {
        /*menu.value = menu.value.map((item: any, i: number) => {
          return i === 2 ? [{
            href: "/mediakiwi/my_vault/shared-assets",
            id: 99,
            text: "Shared Inventory"
          }, item] : item;
        }).flat();*/
      }
    })

    return {
      menu,
      props
    };
  },
});
