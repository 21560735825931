import { computed, defineComponent, ref, Ref } from "vue";

export default defineComponent({
  name: "FilterChip",
  props: ["itemData", "secondary"],
  emits: ["onCancelFilter"],

  setup(props) {
    const item = computed(() => props.itemData);

    return { item, props }
  },
})