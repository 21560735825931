import { Router } from 'vue-router';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export const useAnalytics = (router: Router) => {
  router.afterEach((to) => {
    if (window.gtag) {
      window.gtag('config', process.env.VUE_APP_GA_TRACKING_ID, {
        page_path: to.fullPath,
      });
    }
  });
};