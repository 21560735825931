import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.props.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.props.modelValue) = $event)),
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "medium-modal__container" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            icon: "close",
            class: "dialog-close",
            flat: "",
            round: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDescriptionDialog()))
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "col-12 q-pr-xl q-pl-xl q-pt-xl q-pb-xl",
              innerHTML: _ctx.description
            }, null, 8, _hoisted_2)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}