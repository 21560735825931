import { defineComponent } from "vue";
import DropDownButton from "../dropdownbutton/DropDownButton.vue";

export default defineComponent({
    name: "FabButton",
    components: {
        DropDownButton
    },
    props: {
        isGoogle: {
            type: Boolean,
            default: false
        },
        isAction: {
            type: Boolean,
            default: false
        },
        dropDownProperties: { type: Array }
    }
})
