import { defineComponent, onUpdated, ref } from "vue";
import { currentSiteId } from "@/extensions/extra";
import { ContentModel } from "@/models/content";
import { GET_CONTENT, POST_CONTENT } from "@/services/content-service"
import { returnErrors } from "@/utils/returnErrors";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  name: "Description",
  props: ["originalUrl", "showDialog", "modelValue"],
  emits: ["update:modelValue"],
  components: {
  },

  setup(props, { emit }) {
    const contentModel = new ContentModel();
    const description = ref();
    const $q = useQuasar();

    onUpdated(() => {
      if (props.modelValue == true) {
        getContent();
      }
    })

    async function getContent() {
      contentModel.currentSiteId = currentSiteId;
      contentModel.originalUrl = props.originalUrl;
      description.value = "";
      const [error, response] = await GET_CONTENT(contentModel)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        description.value = response.list.description;
      }
    }

    function closeDescriptionDialog() {
      emit('update:modelValue', false)
    }

    return {
      closeDescriptionDialog,
      getContent,
      currentSiteId,
      props,
      description,
    }
  },
})
