<style lang="scss" src="./App.scss"></style>
<script lang="ts" src="./App.ts"></script>

<template>
  <SignalR v-if="isAuthenticated" />
  <MediaDeviceNotSupported />
  <q-layout>
    <HeaderComponent v-if="isAuthenticated" />
    <Drawer v-if="isAuthenticated" />
    <q-page-container>
      <router-view :key="$route.fullPath" />
    </q-page-container>
  </q-layout>
  <div class="drawing" id="loading" v-if="loadingSpinner">
    <q-linear-progress dark rounded indeterminate color="warning" />
  </div>
</template>
