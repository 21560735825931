import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_q_chip = _resolveComponent("q-chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "user-select__none display-inline",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.item.value !== '-1' ? null : _ctx.$emit('onCancelFilter')))
  }, [
    _createVNode(_component_q_chip, {
      color: _ctx.props.secondary !== undefined ? '' : 'white margin-bottom-15',
      class: _normalizeClass(`${_ctx.item.value !== '-1' ? '' : 'cursor-pointer'} ${_ctx.props.secondary !== undefined ? 'background-color-B5E3FF' : 'q-px-lg border-1-color-B3B3B3'}`),
      style: _normalizeStyle(_ctx.props.secondary !== undefined ? {borderRadius: '24px', height: '36px', margin: '0 4px 0 0'} : {})
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.item.title || _ctx.item.text || _ctx.item) + " ", 1),
        _createVNode(_component_font_awesome_icon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCancelFilter'))),
          class: _normalizeClass(`q-pl-md cursor-pointer ${_ctx.props.secondary !== undefined ? '' : 'red'}`),
          icon: _ctx.item.value !== '-1' ? 'fal fa-close' : 'fal fa-trash',
          size: "sm"
        }, null, 8, ["class", "icon"])
      ]),
      _: 1
    }, 8, ["color", "class", "style"])
  ]))
}