import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_q_breadcrumbs_el = _resolveComponent("q-breadcrumbs-el")!
  const _component_q_breadcrumbs = _resolveComponent("q-breadcrumbs")!
  const _component_Description = _resolveComponent("Description")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_breadcrumbs, null, {
      separator: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          size: "sm",
          icon: "fal fa-angle-right",
          class: "q-pt-xs blue"
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (element) => {
          return (_openBlock(), _createBlock(_component_q_breadcrumbs_el, {
            key: element,
            label: element.lang ? _ctx.$t(element.title) : element.title,
            to: element.path,
            class: _normalizeClass(["breadcrumbs text-h3 text-weight-medium", 
        element.isDisable ? 'breadcrumbs__disable' : 'breadcrumbs__active'
      ]),
            disable: element.isDisable
          }, null, 8, ["label", "to", "class", "disable"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_Description, {
      modelValue: _ctx.state.dialogValues.description,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.dialogValues.description) = $event)),
      originalUrl: _ctx.originalURL
    }, null, 8, ["modelValue", "originalUrl"])
  ], 64))
}