import service from "@/plugins/axios";
import store from "@/store";
import { FilterModel } from "@/models/filter";
import { useOidcStore } from "vue3-oidc";

const request = new service();
const source = "/mkapi/";
const { state } = useOidcStore();

export const FILTER = (filterModel: FilterModel) => {
  return request.axiosCall({
    method: `post`,
    url: `${source}` + "content/PostContent",
    data: filterModel,
    headers: {
      "original-url": filterModel.originalUrl,
      currentSiteId: filterModel.currentSiteId,
      Authorization: store.state.token,
    },
  });
};
