import { defineComponent, onMounted, ref, watch, Ref, reactive, computed } from "vue";
import { GET_CONTENT, POST_CONTENT, GET_CONTENT_WITH_PAGER } from "@/services/content-service"
import { ContentModel } from "@/models/content";
import { currentSiteId, pagerFirstPage } from "@/extensions/extra";
import { TableGridFields } from "@/base/core/enum";
import { formatColumns, formatRows } from "@/utils/formatGrid";
import { formatImages } from "@/utils/formatImages";
import { formatDate } from "@/utils/dateUtil"
import router from "@/router";
import SkuCard from "@/components/sku-card/SkuCard.vue"
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { returnErrors } from "@/utils/returnErrors";
import { utcString } from "@/extensions/extra";

export default defineComponent({
  name: "TablePager",
  props: {
    originalUrl: {
      type: String,
    },
    toUrl: {
      type: String,
    },
    component: {
      type: String,
    },
    filteredData: {
      type: Array,
    },
    responseList: {
      type: Array,
    },
    tableName: {
      type: String,
    },
    actions: {
      type: String,
    },
    filteredPagination: {
      type: Object,
    }
  },
  emits: ["openParentModal", "callToActionButton", "getPropValue", "getRowLength", "openParentDialog", "productId"],
  components: { SkuCard },

  setup(props, { emit }) {
    const contentModel = new ContentModel();
    const rows = ref();
    const cols = ref();
    const filteredDataArr = ref();
    const tableName = ref();
    let hiddenTableFields: any = [];
    watch(() => props.filteredData, (newValue) => filteredDataArr.value = newValue);
    watch(() => props.tableName, (newValue) => tableName.value = newValue);
    //const dateColValues = ["Date", "Created", "Since", "Last login", "Updated", "Valid from", "Valid to", "Expired", "Expires"];
    //const dateColValues: string | any[] = [];
    const dateColValues = ["Date", "Last login", "Effective dates"];
    const isCopiedIndex: Ref<number> = ref(-1)
    const initialPagination = reactive({
      page: 0,
      rowsPerPage: 0,
      rowsNumber: 0
    })
    const _pagination = computed(() => {
      let tempPag = {
        page: 0,
        rowsPerPage: 0,
        rowsNumber: 0,
      }
      if (props.filteredPagination) {
        tempPag.page = props.filteredPagination.currentPage
        tempPag.rowsPerPage = props.filteredPagination.itemsPerPage
        tempPag.rowsNumber = props.filteredPagination.totalItems
      } else {
        tempPag = initialPagination
      }
      return tempPag
    })
    const pagerInfo = computed(() => {
      let tempInfo = ""
      if (_pagination.value.rowsNumber / _pagination.value.rowsPerPage < 1) {
        tempInfo = _pagination.value.page + "-" + _pagination.value.rowsNumber;
      } else if (_pagination.value.rowsNumber / _pagination.value.rowsPerPage >= 1 && _pagination.value.page === 1) {
        tempInfo = _pagination.value.page + "-" + _pagination.value.rowsPerPage;
      } else {
        tempInfo = ((_pagination.value.page * _pagination.value.rowsPerPage) - _pagination.value.rowsPerPage) + "-" + _pagination.value.page * _pagination.value.rowsPerPage
      }
      return tempInfo
    })
    const $q = useQuasar();
    const loading = ref(true);

    onMounted(() => {
      if (!props.responseList) {
        getContent();
      } else {
        getContentWithResponse(props.responseList);
      }
      filteredDataArr.value = props.filteredData;
      tableName.value = props.tableName;
    })

    async function getContent() {
      contentModel.currentSiteId = currentSiteId;
      contentModel.originalUrl = props.originalUrl;
      const [error, response] = await GET_CONTENT(contentModel)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        if (response.list.grids) {
          cols.value = response.list.grids[0].columns;
          rows.value = response.list.grids[0].rows;
          cols.value = formatColumns(response.list.grids[0].columns);
          rows.value = formatRows(response.list.grids[0].rows, response.list.grids[0].columns);
          sendRownLength(rows.value);
          for (let i = 0; i < rows.value.length; i++) {
            for (let k = 0; k < dateColValues.length; k++) {
              if (rows.value[i][dateColValues[k]]) {
                //rows.value[i][dateColValues[k]] = formatDate(rows.value[i][dateColValues[k]], "DD-MM-YYYY HH:mm") + utcString;
                rows.value[i][dateColValues[k]] = rows.value[i][dateColValues[k]] + utcString;
              }
            }
          }
          for (let i = 0; i < cols.value.length; i++) {
            if (cols.value[i].title === TableGridFields.ID.toString() ||
              cols.value[i].title === TableGridFields.GUID.toString() ||
              cols.value[i].title === TableGridFields.Image.toString() ||
              cols.value[i].title === TableGridFields.IsConnected.toString() ||
              cols.value[i].title === TableGridFields.KeyProviderImageURL.toString()) {
              hiddenTableFields.push(i);
            }
          }
          for (let i = hiddenTableFields.length - 1; i >= 0; i--) {
            cols.value.splice(hiddenTableFields[i], 1);
          }
          rows.value = formatImages(rows.value);
          // product list toggle buttons
          for (let i = 0; i < rows.value.length; i++) {
            if (rows.value[i].IsConnected === "True") {
              rows.value[i].IsConnected = true;
            } else {
              rows.value[i].IsConnected = false;
            }
          }
          initialPagination.rowsPerPage = parseInt(response.list.grids[0].pagination.itemsPerPage);
          initialPagination.page = parseInt(response.list.grids[0].pagination.currentPage);
          initialPagination.rowsNumber = parseInt(response.list.grids[0].pagination.totalItems);
        }
      }
      loading.value = false;
    }

    function getContentWithResponse(res: any) {
      cols.value = res.list.grids[0].columns;
      rows.value = res.list.grids[0].rows;
      cols.value = formatColumns(res.list.grids[0].columns);
      rows.value = formatRows(res.list.grids[0].rows, res.list.grids[0].columns);
      sendRownLength(rows.value);
      for (let i = 0; i < rows.value.length; i++) {
        for (let k = 0; k < dateColValues.length; k++) {
          if (rows.value[i][dateColValues[k]]) {
            //rows.value[i][dateColValues[k]] = formatDate(rows.value[i][dateColValues[k]], "DD-MM-YYYY HH:mm") + utcString;
            rows.value[i][dateColValues[k]] = rows.value[i][dateColValues[k]] + utcString;
          }
        }
      }
      for (let i = 0; i < cols.value.length; i++) {
        if (cols.value[i].title === TableGridFields.ID.toString() ||
          cols.value[i].title === TableGridFields.GUID.toString() ||
          cols.value[i].title === TableGridFields.Image.toString() ||
          cols.value[i].title === TableGridFields.IsConnected.toString() ||
          cols.value[i].title === TableGridFields.KeyProviderImageURL.toString()) {
          hiddenTableFields.push(i);
        }
      }
      for (let i = hiddenTableFields.length - 1; i >= 0; i--) {
        cols.value.splice(hiddenTableFields[i], 1);
      }
      rows.value = formatImages(rows.value);
      // product list toggle buttons
      for (let i = 0; i < rows.value.length; i++) {
        if (rows.value[i].IsConnected === "True") {
          rows.value[i].IsConnected = true;
        } else {
          rows.value[i].IsConnected = false;
        }
      }
      initialPagination.rowsPerPage = parseInt(res.list.grids[0].pagination.itemsPerPage);
      initialPagination.page = parseInt(res.list.grids[0].pagination.currentPage);
      initialPagination.rowsNumber = parseInt(res.list.grids[0].pagination.totalItems);
      loading.value = false;
    }

    async function nextPage() {
      loading.value = true;
      contentModel.currentSiteId = currentSiteId;
      contentModel.originalUrl = props.originalUrl;
      cols.value = [];
      rows.value = [];
      hiddenTableFields = [];
      initialPagination.page = initialPagination.page + 1;
      const [error, response] = await GET_CONTENT_WITH_PAGER(contentModel, initialPagination.page)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        cols.value = response.list.grids[0].columns;
        rows.value = response.list.grids[0].rows;
        cols.value = formatColumns(response.list.grids[0].columns);
        rows.value = formatRows(response.list.grids[0].rows, response.list.grids[0].columns);
        for (let i = 0; i < rows.value.length; i++) {
          for (let k = 0; k < dateColValues.length; k++) {
            if (rows.value[i][dateColValues[k]]) {
              //rows.value[i][dateColValues[k]] = formatDate(rows.value[i][dateColValues[k]], "DD-MM-YYYY HH:mm") + utcString;
              rows.value[i][dateColValues[k]] = rows.value[i][dateColValues[k]] + utcString;
            }
          }
        }
        for (let i = 0; i < cols.value.length; i++) {
          if (cols.value[i].title === TableGridFields.ID.toString() ||
            cols.value[i].title === TableGridFields.GUID.toString() ||
            cols.value[i].title === TableGridFields.Image.toString() ||
            cols.value[i].title === TableGridFields.IsConnected.toString() ||
            cols.value[i].title === TableGridFields.KeyProviderImageURL.toString()) {
            hiddenTableFields.push(i);
          }
        }
        for (let i = hiddenTableFields.length - 1; i >= 0; i--) {
          cols.value.splice(hiddenTableFields[i], 1);
        }
        rows.value = formatImages(rows.value);
        // product list toggle buttons
        for (let i = 0; i < rows.value.length; i++) {
          if (rows.value[i].IsConnected === "True") {
            rows.value[i].IsConnected = true;
          } else {
            rows.value[i].IsConnected = false;
          }
        }
      }
      loading.value = false;
    }

    async function prevPage() {
      loading.value = true;
      contentModel.currentSiteId = currentSiteId;
      contentModel.originalUrl = props.originalUrl;
      cols.value = [];
      rows.value = [];
      hiddenTableFields = [];
      initialPagination.page = initialPagination.page - 1;
      const [error, response] = await GET_CONTENT_WITH_PAGER(contentModel, initialPagination.page)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        cols.value = response.list.grids[0].columns;
        rows.value = response.list.grids[0].rows;
        cols.value = formatColumns(response.list.grids[0].columns);
        rows.value = formatRows(response.list.grids[0].rows, response.list.grids[0].columns);
        for (let i = 0; i < rows.value.length; i++) {
          for (let k = 0; k < dateColValues.length; k++) {
            if (rows.value[i][dateColValues[k]]) {
              //rows.value[i][dateColValues[k]] = formatDate(rows.value[i][dateColValues[k]], "DD-MM-YYYY HH:mm") + utcString;
              rows.value[i][dateColValues[k]] = rows.value[i][dateColValues[k]] + utcString;
            }
          }
        }
        for (let i = 0; i < cols.value.length; i++) {
          if (cols.value[i].title === TableGridFields.ID.toString() ||
            cols.value[i].title === TableGridFields.GUID.toString() ||
            cols.value[i].title === TableGridFields.Image.toString() ||
            cols.value[i].title === TableGridFields.IsConnected.toString() ||
            cols.value[i].title === TableGridFields.KeyProviderImageURL.toString()) {
            hiddenTableFields.push(i);
          }
        }
        for (let i = hiddenTableFields.length - 1; i >= 0; i--) {
          cols.value.splice(hiddenTableFields[i], 1);
        }
        rows.value = formatImages(rows.value);
        // product list toggle buttons
        for (let i = 0; i < rows.value.length; i++) {
          if (rows.value[i].IsConnected === "True") {
            rows.value[i].IsConnected = true;
          } else {
            rows.value[i].IsConnected = false;
          }
        }
      }
      loading.value = false;
    }

    function rowClick(evt: any, row: any) {
      let id: any;
      if (props.toUrl) {
        if (props.toUrl == "MyVaultTransactionDetail") {
          id = row.GUID;
        } else {
          id = row.ID;
        }
        router.push({ name: props.toUrl, params: { id } });
      } else {
        sendPropToParent(row);
      }
    }

    function sendPropToParent(row: any) {
      emit('openParentModal', row);
    }

    function sendPropValueToParent(value: any) {
      emit('getPropValue', value);
    }

    function callToActionButtonParent(value: any, status: any) {
      emit('callToActionButton', value, status, rows.value);
    }

    function sendRownLength(arr: any) {
      emit('getRowLength', arr.length);
    }

    function openDialogParent(rowValue: any, dialogType: any) {
      emit('openParentDialog', rowValue.GUID, dialogType);
      emit('productId', rowValue.ID)
    }

    function toClipboard(index: number) {
      isCopiedIndex.value = index
      setTimeout(function () {
        isCopiedIndex.value = -1;
      }, 800);
    }

    return {
      nextPage,
      prevPage,
      sendPropToParent,
      callToActionButtonParent,
      sendPropValueToParent,
      sendRownLength,
      getContent,
      getContentWithResponse,
      openDialogParent,
      rowClick,
      currentSiteId,
      rows,
      cols,
      componentName: props.toUrl,
      componentPage: props.component,
      actionsCell: props.actions,
      filteredDataArr,
      initialPagination,
      isCopiedIndex,
      toClipboard,
      _pagination,
      pagerInfo,
      loading
    }
  },
})