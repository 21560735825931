import { defineComponent } from "vue";

export default defineComponent({
  name: "PageSpace",
  props: {
    parentClass: {
      type: String,
      default: "row padding-left-100 padding-right-35 q-mt-lg"
    },
    childClass: {
      type: String,
      default: "col-12 q-mb-lg"
    }
  },
  setup(props) {
    return { props }
  },
})