import { TableGridFields } from "@/base/core/enum";

const noImage = "/img/img-prod-portrait.png";
const noImageVaultn = "/img/img-logo@2x.png";
const imageSize64 = "64/0/2";
const imageSize32 = "32/0/2";
const PackShotImageURL = TableGridFields.PackShotImageURL.toString();
const KeyProviderImageURL = TableGridFields.KeyProviderImageURL.toString();
const VaultImageURL = TableGridFields.VaultImageURL.toString();
const PlatformDRM = TableGridFields.PlatformDRM.toString();

export function formatImages(rows: any) {
  for (let i = 0; i < rows.length; i++) {
    if (PackShotImageURL in rows[i]) {
      let pos: any = 0;
      let result: any = "";
      if (rows[i][PackShotImageURL] != "") {
        pos = rows[i][PackShotImageURL].indexOf("[");
        result = rows[i][PackShotImageURL].slice(0, pos) + imageSize64;
        rows[i][PackShotImageURL] = result;
      } else {
        result = noImage;
        rows[i][PackShotImageURL] = result;
      }
    }
  }

  for (let i = 0; i < rows.length; i++) {
    if (KeyProviderImageURL in rows[i]) {
      let pos: any = 0;
      let result: any = "";
      if (rows[i][KeyProviderImageURL.toString()] != "") {
        pos = rows[i][KeyProviderImageURL.toString()].indexOf("[");
        result = '<span class="keyProviderImage"><img src="' + rows[i][KeyProviderImageURL.toString()].slice(0, pos) + imageSize32 + '"></span>';
        rows[i][KeyProviderImageURL] = result;
      }
    }
  }

  for (let i = 0; i < rows.length; i++) {
    if (KeyProviderImageURL in rows[i] && PlatformDRM in rows[i]) {
      const result = rows[i][KeyProviderImageURL] + '<span class="keyProvider">' + rows[i][PlatformDRM] + '</span>';
      rows[i][PlatformDRM] = result;
    }
  }

  // standart format example for Vault
  for (let i = 0; i < rows.length; i++) {
    if (VaultImageURL in rows[i]) {
      let pos: any = 0;
      let result: any = "";
      if (rows[i][VaultImageURL] != "") {
        pos = rows[i][VaultImageURL].indexOf("[");
        result = rows[i][VaultImageURL].slice(0, pos) + imageSize64;
        rows[i][VaultImageURL] = result;
      } else {
        result = noImageVaultn;
        rows[i][VaultImageURL] = result;
      }
    }
  }

  return rows;
}