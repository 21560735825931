import { defineComponent } from "vue";

export default defineComponent({
  name: "QInputS",

  setup() {
    return {
    }
  },
})

