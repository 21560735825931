import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "top-navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: "float-left margin-right-20"
      }, [
        (item.id == _ctx.highlightedIndex)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              color: "primary",
              style: {"color":"#2aa1e8 !important","font-weight":"600 !important"},
              label: item.text,
              class: "highlighted",
              to: item.href
            }, null, 8, ["label", "to"]))
          : (_openBlock(), _createBlock(_component_q_btn, {
              key: 1,
              color: "primary",
              label: item.text,
              to: item.href
            }, null, 8, ["label", "to"]))
      ]))
    }), 128))
  ]))
}