import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "top-navigation-administration" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.text,
        class: "float-left margin-right-12"
      }, [
        (item.items.length > 0)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              color: "primary",
              label: item.text,
              class: _normalizeClass([item.isHighlighted ? 'highlighted' : ''])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_menu, {
                  "transition-show": "jump-down",
                  "transition-hide": "jump-up",
                  class: "q-btn-q-menu"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (itemSub) => {
                      return (_openBlock(), _createBlock(_component_q_list, {
                        key: itemSub.text
                      }, {
                        default: _withCtx(() => [
                          (itemSub.text !== 'Market Settings')
                            ? _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                key: 0,
                                clickable: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_router_link, {
                                        to: itemSub.href
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(itemSub.text), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["to"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)), [
                                [_directive_close_popup]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["label", "class"]))
          : _createCommentVNode("", true),
        (item.items.length <= 0 && item.text !== 'Market Settings')
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 1,
              color: "primary",
              label: item.text,
              class: _normalizeClass(item.isHighlighted ? 'highlighted' : ''),
              style: _normalizeStyle(
          item.isHighlighted
            ? 'color: #2aa1e8 !important;font-weight: 600 !important;'
            : ''
        ),
              to: item.href
            }, null, 8, ["label", "class", "style", "to"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}