<style lang="scss" src="./Description.scss"></style>
<script lang="ts" src="./Description.ts"></script>

<template>
  <q-dialog v-model="props.modelValue" persistent>
    <q-card class="medium-modal__container">
      <q-btn
        icon="close"
        class="dialog-close"
        flat
        round
        @click="closeDescriptionDialog()"
      />
      <div class="row">
        <div
          class="col-12 q-pr-xl q-pl-xl q-pt-xl q-pb-xl"
          v-html="description"
        ></div>
      </div>
    </q-card>
  </q-dialog>
</template>
