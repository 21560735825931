import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-center min-width-200 display-inline_block" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "blue font-weight-600 font-size-12" }
const _hoisted_4 = { class: "font-weight-600 font-size-24" }
const _hoisted_5 = { class: "font-weight-600 font-size-18" }
const _hoisted_6 = { class: "row fit justify-between items-center text-weight-medium blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.sku && typeof _ctx.sku === 'string' && !_ctx.sku.includes('-ALL-'))
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 0,
          class: "button table-btn copy",
          flat: "",
          color: "primary",
          onClick: _withModifiers(_ctx.copySku, ["stop"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-light fa-copy",
              size: "2xl",
              class: "blue"
            }),
            _createVNode(_component_q_tooltip, { class: "font-size-14 background-color-29A0E8" }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.isCoppied ? _ctx.$t("sku.copied") : _ctx.sku), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]))
      : (_ctx.sku && typeof _ctx.sku === 'object' && !_ctx.sku.includes('-ALL-'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_q_btn, {
              class: "button table-btn copy",
              flat: "",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialog = true), ["stop"]))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "fa-light fa-copy",
                  size: "2xl",
                  class: "blue"
                }),
                _createVNode(_component_q_tooltip, { class: "font-size-14 background-color-29A0E8" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sku, (item) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: item,
                        class: "block min-width-200"
                      }, _toDisplayString(item), 1))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("span", _hoisted_3, "x" + _toDisplayString(_ctx.sku.length), 1)
          ]))
        : (_openBlock(), _createBlock(_component_q_btn, {
            key: 2,
            class: "button table-btn copy",
            flat: "",
            color: "grey-8"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "fa-light fa-ban",
                size: "2xl",
                class: "blue"
              }),
              _createVNode(_component_q_tooltip, { class: "font-size-14 background-color-29A0E8" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("table.noSKU")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isDialog,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDialog) = $event)),
      position: "left"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { style: {"transform":"translateX(35%)","min-width":"400px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sku.copySKUs")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.props.rowData.Name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator, { horizontal: "" }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sku, (item) => {
                  return (_openBlock(), _createElementBlock("div", { key: item }, [
                    _createVNode(_component_q_checkbox, {
                      class: "text-weight-medium",
                      modelValue: _ctx.checkedSKUs,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedSKUs) = $event)),
                        _ctx.selectedCheck
                      ],
                      val: item,
                      label: item,
                      "true-value": item
                    }, null, 8, ["modelValue", "val", "label", "true-value", "onUpdate:modelValue"])
                  ]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator, { horizontal: "" }),
            _createVNode(_component_q_card_actions, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_checkbox, {
                      class: "font-size-14",
                      modelValue: _ctx.allArr,
                      "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.allArr) = $event)),
                        _ctx.allChecked
                      ],
                      val: _ctx.sku,
                      label: _ctx.$t('sku.all')
                    }, null, 8, ["modelValue", "val", "label", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", null, [
                    _withDirectives(_createVNode(_component_q_btn, {
                      class: "font-size-14",
                      flat: "",
                      label: _ctx.$t('sku.cancel'),
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkedSKUs = []))
                    }, null, 8, ["label"]), [
                      [_directive_close_popup]
                    ]),
                    _withDirectives(_createVNode(_component_q_btn, {
                      onClick: _ctx.multipleCopy,
                      class: "font-size-14",
                      flat: "",
                      label: _ctx.$t('sku.ok')
                    }, null, 8, ["onClick", "label"]), [
                      [_directive_close_popup]
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}